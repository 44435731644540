import { stAnalytics } from "@repo/analytics";
import { BsPersonFillAdd } from "solid-icons/bs";
import { TbSettings } from "solid-icons/tb";
import { createEffect, createSignal, Show, Suspense, type Component } from "solid-js";
import { CollectionBreadcrumbs } from "@core/components/CollectionBreadcrumbs";
import { Anchor } from "@core/components/cta/Anchor";
import type { CollectionAccessRecord } from "@core/domains/collections/collections.types";
import { getScreenType } from "@core/lib/ui/getScreenType";
import { PermissionsDrawerScreen } from "@core/screens/drawer/PermissionsDrawerScreen";
import { useUIState } from "@core/ui/UIState";
import { useWire } from "@core/wire";
import styles from "./CollectionsTitleBar.module.css";
import { SecondaryCTA } from "./cta/SecondaryCTA";
import { UserAvatarNest } from "./UserAvatarNest";
import { CollectionsContextMenu } from "./context-menu/CollectionsContextMenu";
import type { curator } from "@repo/client";

export const CollectionsTitleBar: Component<{
  label: string;
  description?: string;
  collectionId: string;
  breadcrumbs?: Breadcrumb[];
  onCollectionCreated: (label: string) => void;
  access?: CollectionAccessRecord[];
  openInvite?: curator.OpenDomainInvitation;
  shareableLink?: curator.SecretLinkCollectionToken;
}> = (props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setRightOpen] = state.rightDrawer;
  const [, setRightContents] = state.rightDrawerContents;
  const [initiallyOpen, setInitiallyOpen] = state.openShareDrawer;
  const [configRef, setConfigRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const configId = () => `collections-title-bar-config-${props.collectionId}`;

  const PermissionDrawerContents = () => (
    <PermissionsDrawerScreen
      access={props.access}
      collectionId={props.collectionId}
      openInvite={props.openInvite}
      shareableLink={props.shareableLink}
    />
  );

  const openShareDrawer = () => {
    setRightContents(() => PermissionDrawerContents);
    setRightOpen(true);
  };

  createEffect(() => {
    if (initiallyOpen()) {
      openShareDrawer();
      setInitiallyOpen(false);
    }
  });

  const isItemYou = (item: CollectionAccessRecord) => item.user_id === wire.services.auth.user()?.userId;

  const itemName = (item: CollectionAccessRecord) =>
    isItemYou(item) ? "You" : item.sortableName === "Guest" ? item.email : item.sortableName;

  const withAccess = () =>
    props.access?.filter((r) => r.accessType === "user").sort((a, b) => (isItemYou(a) ? -1 : isItemYou(b) ? 1 : 0));

  return (
    <header data-component="rhs-title" class={styles["collections-title-bar"]}>
      <div class={styles["collections-title-bar__left"]}>
        <CollectionBreadcrumbs max={3} breadcrumbs={props.breadcrumbs || []} limited />
        <div class="flex items-center gap-1">
          <h1 class={styles["collections-title-bar__header"]}>
            <span class={styles["collections-title-bar__header-text"]}>{props.label}</span>
            <span class="screen-reader">Collection</span>
          </h1>

          <button
            ref={(ref) => {
              setConfigRef(ref);
            }}
            class={styles["collections-title-bar__config"]}
            type="button"
            aria-controls={configId()}
          >
            <TbSettings class={styles["collections-title-bar__header-icon"]} size="1.75rem" />
            <span class="screen-reader">Configure the Collection.</span>
          </button>
          <CollectionsContextMenu
            onCollectionCreated={props.onCollectionCreated}
            bound={configRef()}
            id={configId()}
            collectionId={props.collectionId}
            placement="bottom-start"
          />
        </div>
        <Show when={props.description}>
          <p class={styles["collections-title-bar__header-description"]}>{props.description}</p>
        </Show>
        <Show when={props.label === "Personal Collection"}>
          <p class={styles["collections-title-bar__header-docs"]}>
            <Anchor
              accessibleSuffix="Visit our documentation to"
              data-test-id="getting-started-collections-learn-more"
              class={styles["collections-title-bar__header-link"]}
              href="https://docs.storytell.ai/features/collections"
              target="_blank"
              label="Learn more about Collections."
              small
              onClick={() => {
                stAnalytics.track("click_tracking", {
                  cta: "documentation",
                  position: "main_content_title",
                  screen: getScreenType(),
                });
              }}
            />
          </p>
        </Show>
        <Show when={props.label === "Shared Collections"}>
          <p class={styles["collections-title-bar__header-docs"]}>
            <Anchor
              accessibleSuffix="Visit our documentation to"
              data-test-id="getting-started-collaboration-learn-more"
              class={styles["collections-title-bar__header-link"]}
              href="https://docs.storytell.ai/features/collections/collaboration"
              target="_blank"
              label="Learn more about sharing & collaboration."
              small
              onClick={() => {
                stAnalytics.track("click_tracking", {
                  cta: "documentation",
                  position: "main_content_title",
                  screen: getScreenType(),
                });
              }}
            />
          </p>
        </Show>
        {/* <div class={styles["collections-title-bar__header-create"]}>
          <PrimaryCTA
            label="Chat with this Collection"
            data-test-id="thread-top-create-chat"
            icon={TbMessagePlus}
            onClick={async () => {
              stAnalytics.track("click_tracking", {
                cta: "create_chat",
                position: "main_content_title",
                screen: getScreenType(),
              });
              await onCreateSmartchat();
            }}
            accessibleSuffix="in your current Collection."
          />
        </div> */}
      </div>
      <div class={styles["collections-title-bar__right"]}>
        <Show when={wire.services.collections.getCanShareCollection(props.collectionId) && withAccess()?.length !== 1}>
          <Suspense>
            <button
              type="button"
              class={styles["collections-title-bar__avatars"]}
              onClick={openShareDrawer}
              tabIndex={0}
            >
              <UserAvatarNest
                max={5}
                avatars={
                  withAccess()
                    ?.filter((u) => u.user_id !== wire.services.auth.user()?.userId)
                    ?.map((user) =>
                      user.sortableName === "Guest" && !user.email
                        ? {
                            name: itemName(user),
                            isGuest: true,
                            size: "share",
                          }
                        : {
                            name: itemName(user),
                            initial: itemName(user).slice(0, 1),
                            size: "share",
                          },
                    ) ?? []
                }
              />
            </button>
          </Suspense>
        </Show>

        <Show when={wire.services.collections.getCanShareCollection(props.collectionId)}>
          <Suspense>
            <SecondaryCTA
              label="Invite"
              data-test-id="thread-top-create-chat"
              icon={BsPersonFillAdd}
              onClick={openShareDrawer}
              class="flex-shrink-0 w-auto ml-3"
              accessibleSuffix="users to your current Collection."
            />
          </Suspense>
        </Show>
        {/* <Show when={wire.services.collections.getCanShareCollection(props.collectionId)}>
          <ul class={styles["collections-title-bar__menu"]}>
            <li class={styles["collections-title-bar__menu-item"]}>
            <IconShareCTA
              data-test-id="title-star-collection"
              accessibleLabel="Star the Collection."
              isFavorite={true}
            />
          </li>
            <li class={styles["collections-title-bar__menu-item"]}>
              <IconCTA
                data-test-id="collections-title-action-menu"
                accessibleLabel="Collaborate on this Collection."
                icon={FaSolidPeopleGroup}
                onClick={openShareDrawer}
                modifiers={["secondary-filled", "small", "collaborate"]}
              />
            </li>
          </ul>
        </Show> */}
      </div>
    </header>
  );
};
