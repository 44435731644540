import { APIError, type curator, getRequestClient } from "@repo/client";
import {
  TbClipboardCheck,
  TbClipboardCopy,
  TbLinkOff,
  TbUserPlus,
  TbUsersGroup,
  TbUsersMinus,
  TbUsersPlus,
} from "solid-icons/tb";
import { type Component, For, Show, Suspense, createSignal, startTransition } from "solid-js";
import { EditUserPermissions, type EditUserPermissionsOptions } from "@core/components/EditUserPermissions";
import { ContentContainer } from "@core/components/containers/ContentContainer";
import { PrimaryCTA } from "@core/components/cta/PrimaryCTA";
import { SecondaryCTA } from "@core/components/cta/SecondaryCTA";
import { type UsersTagListTag, UsersTagsList } from "@core/components/fields/UsersTagsList";
import type { CollectionAccessRecord } from "@core/domains/collections/collections.types";
import { formatCollectionName } from "@core/lib/ui/formatCollectionName";
import { urls } from "@core/lib/urls";
import { useUIState } from "@core/ui/UIState";
import { CustomEvents } from "@core/ui/custom-events";
import { useWire } from "@core/wire";
import { RemoveAccessModal } from "../modals/RemoveAccessModal";
import styles from "./PermissionsDrawerScreen.module.css";
import { Tabs } from "@kobalte/core";
import { StIcon } from "@core/components/icons";
import { RevokeOrgAccessModal } from "../modals/RevokeOrgAccessModal";
import { RevokeCollectionLinkModal } from "../modals/RevokeCollectionLinkModal";

export const PermissionsDrawerScreen: Component<{
  collectionId: string;
  access?: CollectionAccessRecord[];
  openInvite?: curator.OpenDomainInvitation;
  shareableLink?: curator.SecretLinkCollectionToken;
}> = (props) => {
  const wire = useWire();
  const auth = wire.services.auth;

  const canUserShareWithOrg = () => {
    if (!auth.isReady()) return false;
    return (auth.user()?.potentialScore ?? 0) >= 5;
  };

  return (
    <Suspense>
      <div>
        <p class={styles["permissions-drawer__title"]}>Collaboration</p>

        <Tabs.Root defaultValue="specific">
          <div class="flex justify-center">
            <Tabs.List class="relative inline-flex items-center gap-2 bg-surface border border-background-decoration rounded-md">
              <Tabs.Indicator aria-hidden class="pointer-events-none absolute transition-transform inset-y-0 p-0.5">
                <div class="bg-primary rounded w-full h-full" />
              </Tabs.Indicator>

              <Tabs.Trigger
                class="relative min-h-max py-3 flex items-center gap-3 tracking-wide cursor-pointer w-auto min-w-max leading-normal text-base text-on-surface px-3 data-[selected]:text-on-primary transition-colors"
                value="specific"
              >
                <StIcon icon={TbUserPlus} />
                Specific Users
              </Tabs.Trigger>
              <div aria-hidden class="select-none bg-background-decoration w-[1px] h-4" />

              <Tabs.Trigger
                class="relative min-h-max py-3 flex items-center gap-3 tracking-wide cursor-pointer w-auto min-w-max leading-normal text-base text-on-surface px-3 data-[selected]:text-on-primary transition-colors"
                value="link"
              >
                <StIcon icon={TbUserPlus} />
                Anyone with the link
              </Tabs.Trigger>

              <Show when={canUserShareWithOrg()}>
                <div aria-hidden class="select-none bg-background-decoration w-[1px] h-4" />
                <Tabs.Trigger
                  class="relative min-h-max py-3 flex items-center gap-3 tracking-wide cursor-pointer w-auto min-w-max leading-normal text-base text-on-surface px-3 data-[selected]:text-on-primary transition-colors"
                  value="company"
                >
                  <StIcon icon={TbUsersGroup} />
                  Entire Company
                </Tabs.Trigger>
              </Show>
            </Tabs.List>
          </div>
          <Tabs.Content value="specific">
            <InviteScreen collectionId={props.collectionId} access={props.access} />
          </Tabs.Content>
          <Tabs.Content value="link">
            <AnyoneWithLinkScreen
              collectionId={props.collectionId}
              access={props.access}
              shareableLink={props.shareableLink}
            />
          </Tabs.Content>

          <Tabs.Content value="company">
            <CompanyScreen collectionId={props.collectionId} access={props.access} openInvite={props.openInvite} />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </Suspense>
  );
};

const CompanyScreen: Component<{
  collectionId: string;
  access?: CollectionAccessRecord[];
  openInvite?: curator.OpenDomainInvitation;
}> = (props) => {
  const wire = useWire();
  const auth = wire.services.auth;
  const collection = () => wire.services.collections.getCollection(props.collectionId);

  const [loading, setLoading] = createSignal(false);
  const [error, setError] = createSignal<string | null>(null);

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  const domain = () => {
    if (!auth.isReady()) return;
    return auth.user()?.email?.split("@")[1];
  };

  const withAccess = () => props.access?.filter((r) => ["creator", "domainInvite"].includes(r.shareDirective));

  const client = getRequestClient(wire.services.auth.token);

  const enableCompanyAccess = async () => {
    if (loading()) return;

    setLoading(true);
    setError(null);
    try {
      await client.controlplane.ShareCollection({
        collectionIds: [props.collectionId],
        shareTo: [],
        shareToEmails: [],
        shareToEmailsOnDomain: domain() ?? "",
        actions: ["collection:read"],
        shareToSecretLink: false,
      });

      CustomEvents.collectionPermissionChange.dispatch();
    } catch (error) {
      if (error instanceof APIError) setError(error.message);
      else setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const revokeCompanyAccess = async () => {
    try {
      if (!props.openInvite) return;

      await client.controlplane.DeleteOpenDomainInvitation(props.openInvite.id, {
        revokeAccess: true,
      });

      setModalOpen("");
      setModalContents(null);

      CustomEvents.collectionPermissionChange.dispatch();
    } catch (error) {
      if (error instanceof APIError) setError(error.message);
      else setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showRevokeModal = () => {
    setModalContents(() => () => {
      const invite = props.openInvite;
      if (!invite) return null;
      return (
        <RevokeOrgAccessModal
          id="revoke-org-access-modal"
          invite={invite}
          onDelete={revokeCompanyAccess}
          label={collection()?.label || ""}
        />
      );
    });
    setModalOpen("revoke-org-access-modal");
  };

  const options: EditUserPermissionsOptions = [{ value: "collection:read", label: "Viewer" }];
  const isItemYou = (item: CollectionAccessRecord) => item.user_id === auth.user()?.userId;
  const itemName = (item: CollectionAccessRecord) => (item.sortableName === "Guest" ? item.email : item.sortableName);

  return (
    <div class="text-on-surface mt-10">
      <Show
        when={!props.openInvite}
        fallback={
          <>
            <p class="type-title-lg mb-2">
              {collection()?.label} has been shared to all{" "}
              <span class="font-medium text-on-surface-primary">@{props.openInvite?.domain}</span> users.
            </p>
            <p class="type-body opacity-70 mb-4">
              All users with an @{props.openInvite?.domain} email can access "{collection()?.label}" from their "Shared
              Collections" list.
            </p>

            <div class="flex justify-end">
              <PrimaryCTA
                onClick={showRevokeModal}
                loading={loading()}
                accessiblePrefix=""
                data-test-id="enable-company-access"
                icon={TbUsersMinus}
                label="Revoke"
              />
            </div>
          </>
        }
      >
        <p class="type-title-lg mb-2">
          Allow any <span class="font-medium text-on-surface-primary">@{domain()}</span> user to access "
          {collection()?.label}".
        </p>
        <p class="type-body opacity-70 mb-4">
          All users with an @{domain()} email who sign up for Storytell will have have access to "{collection()?.label}"
          from their "Shared Collections" list.
        </p>

        <Show when={error()}>
          <p class="type-body opacity-70 mb-4 text-on-background-danger">{error()}</p>
        </Show>

        <div class="flex justify-end">
          <PrimaryCTA
            onClick={enableCompanyAccess}
            loading={loading()}
            accessiblePrefix=""
            data-test-id="enable-company-access"
            icon={TbUsersPlus}
            label="Enable"
          />
        </div>
      </Show>

      <Show when={withAccess()?.length}>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Current users</p>
          <For each={withAccess()}>
            {(item) => (
              <EditUserPermissions
                avatar={{
                  name: itemName(item),
                  size: "inline",
                  isGuest: item.sortableName === "Guest",
                }}
                disabled
                isYou={isItemYou(item)}
                value={item.actions[0]}
                options={options}
                inherits={
                  item.collection_id !== collection()?.id
                    ? {
                        collectionId: item.collection_id,
                        label: item.collectionLabel,
                      }
                    : undefined
                }
              />
            )}
          </For>
        </ContentContainer>
      </Show>
    </div>
  );
};

const AnyoneWithLinkScreen: Component<{
  collectionId: string;
  access?: CollectionAccessRecord[];
  shareableLink?: curator.SecretLinkCollectionToken;
}> = (props) => {
  const wire = useWire();
  const auth = wire.services.auth;
  const collection = () => wire.services.collections.getCollection(props.collectionId);

  const [loading, setLoading] = createSignal(false);
  const [error, setError] = createSignal<string | null>(null);

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  const domain = () => {
    if (!auth.isReady()) return;
    return auth.user()?.email?.split("@")[1];
  };

  const withAccess = () => props.access?.filter((r) => ["creator", "secretLink"].includes(r.shareDirective));

  const client = getRequestClient(wire.services.auth.token);

  const generateShareableLink = async () => {
    if (loading()) return;

    setLoading(true);
    setError(null);
    try {
      await client.controlplane.ShareCollection({
        collectionIds: [props.collectionId],
        shareTo: [],
        shareToEmails: [],
        shareToEmailsOnDomain: "",
        actions: ["collection:read"],
        shareToSecretLink: true,
      });

      CustomEvents.collectionPermissionChange.dispatch();
    } catch (error) {
      if (error instanceof APIError) setError(error.message);
      else setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const revokeShareableLink = async () => {
    try {
      if (!props.shareableLink) return;

      await client.controlplane.DeleteCollectionsSecretLink(props.shareableLink.tokenId);

      setModalOpen("");
      setModalContents(null);

      CustomEvents.collectionPermissionChange.dispatch();
    } catch (error) {
      if (error instanceof APIError) setError(error.message);
      else setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showRevokeModal = () => {
    setModalContents(() => () => {
      const token = props.shareableLink;
      if (!token) return null;
      return (
        <RevokeCollectionLinkModal
          id="revoke-org-access-modal"
          token={token}
          onDelete={revokeShareableLink}
          label={collection()?.label || ""}
        />
      );
    });
    setModalOpen("revoke-org-access-modal");
  };

  const options: EditUserPermissionsOptions = [{ value: "collection:read", label: "Viewer" }];
  const isItemYou = (item: CollectionAccessRecord) => item.user_id === auth.user()?.userId;
  const itemName = (item: CollectionAccessRecord) => (item.sortableName === "Guest" ? item.email : item.sortableName);

  const fullLink = () =>
    `https://${APP_DOMAIN}${urls.acceptCollectionShareableLink(props.shareableLink?.publicToken ?? "")}`;

  return (
    <div class="text-on-surface mt-10">
      <Show
        when={!props.shareableLink}
        fallback={
          <>
            <p class="type-title-lg mb-2">Anyone with the link.</p>
            <p class="type-body opacity-70 mb-4">
              Anyone with this link will have access to the "{collection()?.label}" Collection after creating an
              account.
            </p>

            <div class="flex items-center gap-2 max-w-full mb-4">
              <div class="relative flex-auto overflow-hidden rounded-lg">
                <p class="select-all rounded-lg bg-surface text-on-surface px-2 py-2 overflow-x-auto whitespace-nowrap scrollbar-hide">
                  {fullLink()}
                </p>
                <span class="absolute inset-y-0 right-0 w-40 pointer-events-none bg-gradient-to-l from-surface to-transparent" />
              </div>
              <div class="flex-shrink-0">
                <CopyLinkButton toCopy={fullLink()} />
              </div>
            </div>

            <div class="flex justify-end">
              <SecondaryCTA
                onClick={showRevokeModal}
                accessiblePrefix=""
                data-test-id="enable-company-access"
                icon={TbLinkOff}
                label="Revoke link"
              />
            </div>
          </>
        }
      >
        <p class="type-title-lg mb-2">Anyone with the link.</p>
        <p class="type-body opacity-70 mb-4">
          Generate a shareable link for "{collection()?.label}". Anyone with the link will have access to it after
          creating an account.
        </p>

        <Show when={error()}>
          <p class="type-body opacity-70 mb-4 text-on-background-danger">{error()}</p>
        </Show>

        <div class="flex justify-end">
          <PrimaryCTA
            onClick={generateShareableLink}
            loading={loading()}
            accessiblePrefix=""
            data-test-id="enable-company-access"
            icon={TbUsersPlus}
            label="Generate link"
          />
        </div>
      </Show>

      <Show when={withAccess()?.length}>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Current users</p>
          <For each={withAccess()}>
            {(item) => (
              <EditUserPermissions
                avatar={{
                  name: itemName(item),
                  size: "inline",
                  isGuest: item.sortableName === "Guest",
                }}
                disabled
                isYou={isItemYou(item)}
                value={item.actions[0]}
                options={options}
                inherits={
                  item.collection_id !== collection()?.id
                    ? {
                        collectionId: item.collection_id,
                        label: item.collectionLabel,
                      }
                    : undefined
                }
              />
            )}
          </For>
        </ContentContainer>
      </Show>
    </div>
  );
};

const CopyLinkButton: Component<{ toCopy: string }> = (props) => {
  const [copied, setCopied] = createSignal(false);

  const copy = () => {
    navigator.clipboard.writeText(props.toCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <PrimaryCTA
      onClick={copy}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
      size="small"
      data-test-id="copy-link"
      accessiblePrefix="Click to "
      label={copied() ? "Copied!" : "Copy link"}
    />
  );
};

const InviteScreen: Component<{
  collectionId: string;
  access?: CollectionAccessRecord[];
}> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.auth.token);
  const collection = () => wire.services.collections.getCollection(props.collectionId);

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;

  const sendInvites = async (tags: UsersTagListTag[]) => {
    await client.controlplane.ShareCollection({
      collectionIds: [props.collectionId],
      shareTo: tags.filter((t) => !!t.avatar).map((t) => t.id),
      shareToEmails: tags.filter((t) => t.email).map((t) => t.id),
      actions: ["collection:read"],
      shareToEmailsOnDomain: "",
      shareToSecretLink: false,
    });

    startTransition(() => {
      CustomEvents.collectionPermissionChange.dispatch();
    });
  };

  const options: EditUserPermissionsOptions = [{ value: "collection:read", label: "Viewer" }];

  const revokeInvite = async (token: string) => {
    await client.controlplane.DeleteToken(token);
    startTransition(() => {
      CustomEvents.collectionPermissionChange.dispatch();
    });
  };

  const revokeAccess = (userId: string, collectionId: string) => async () => {
    await wire.services.collections.apiModifyCollectionUserPermissions({
      collectionId,
      userId,
      actions: [],
    });
    CustomEvents.collectionPermissionChange.dispatch();
  };

  const isItemYou = (item: CollectionAccessRecord) => item.user_id === wire.services.auth.user()?.userId;
  const itemName = (item: CollectionAccessRecord) => (item.sortableName === "Guest" ? item.email : item.sortableName);
  const withAccess = () =>
    props.access
      ?.filter((r) => r.accessType === "user" && ["creator", "directInvite"].includes(r.shareDirective))
      .sort((a, b) => (isItemYou(a) ? -1 : isItemYou(b) ? 1 : 0));

  return (
    <>
      <Show when={wire.services.collections.getCanShareCollection(props.collectionId)}>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>
            Invite users {collection()?.label ? ` to the ${formatCollectionName(collection()?.label)}` : ""}
          </p>
          <UsersTagsList
            sendInvites={sendInvites}
            options={options}
            access={props.access?.filter((a) => a.collection_id === props.collectionId)}
          />
        </ContentContainer>
      </Show>

      <Show when={props.access?.filter((r) => r.accessType === "token").length}>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Pending invites</p>
          <For each={props.access?.filter((r) => r.accessType === "token")}>
            {(item) => (
              <EditUserPermissions
                avatar={{
                  name: itemName(item),
                  size: "inline",
                  initial: itemName(item).substring(0, 1),
                }}
                isYou={isItemYou(item)}
                disabled
                value={item.actions[0]}
                options={options}
                inherits={
                  item.collection_id !== collection()?.id
                    ? {
                        collectionId: item.collection_id,
                        label: item.collectionLabel,
                      }
                    : undefined
                }
                actions={
                  <>
                    <SecondaryCTA
                      size="small"
                      data-test-id="revoke-invite"
                      accessiblePrefix="Click to "
                      label="Revoke"
                      accessibleSuffix=" invite"
                      onClick={() => revokeInvite(item.token)}
                    />
                    <CopyInviteButton token={item.publicToken} />
                  </>
                }
              />
            )}
          </For>
        </ContentContainer>
      </Show>

      <Show when={withAccess()?.length}>
        <ContentContainer modifier="wide">
          <p class={styles["permissions-drawer__section-title"]}>Current users</p>
          <For each={withAccess()}>
            {(item) => (
              <EditUserPermissions
                avatar={{
                  name: itemName(item),
                  size: "inline",
                  isGuest: item.sortableName === "Guest",
                }}
                disabled
                isYou={isItemYou(item)}
                value={item.actions[0]}
                options={options}
                inherits={
                  item.collection_id !== collection()?.id
                    ? {
                        collectionId: item.collection_id,
                        label: item.collectionLabel,
                      }
                    : undefined
                }
                actions={
                  <Show when={item.user_id !== wire.services.auth.user()?.userId}>
                    <SecondaryCTA
                      size="small"
                      data-test-id="revoke-invite"
                      accessiblePrefix="Click to "
                      label="Remove"
                      accessibleSuffix=" from collection"
                      onClick={() => {
                        setModalContents(() => () => (
                          <RemoveAccessModal
                            id="remove-access-modal"
                            identifier={itemName(item)}
                            collectionName={item.collectionLabel}
                            onRevoke={revokeAccess(item.user_id, item.collection_id)}
                          />
                        ));
                        setModalOpen("remove-access-modal");
                      }}
                    />
                  </Show>
                }
              />
            )}
          </For>
        </ContentContainer>
      </Show>
    </>
  );
};

const CopyInviteButton: Component<{ token: string }> = (props) => {
  const link = (token: string) => `${window.location.origin}${urls.acceptInvite(token)}`;
  const [copied, setCopied] = createSignal(false);

  const copy = () => {
    navigator.clipboard.writeText(link(props.token));
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <PrimaryCTA
      onClick={copy}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
      size="small"
      data-test-id="copy-invite"
      accessiblePrefix="Click to "
      label={copied() ? "Copied!" : "Copy invite"}
    />
  );
};
