import type { ValidationMethods } from "@repo/validation";
import type { IconTypes } from "solid-icons";
import { Show, createSignal, createUniqueId } from "solid-js";
import { Portal } from "solid-js/web";
import { ContextMenu, type ContextMenuProps } from "@core/components/context-menu/ContextMenu";
import { classNames } from "@core/lib/classNames";
import { useUIState } from "@core/ui/UIState";
import styles from "./PseudoSelect.module.css";
import { StIcon } from "../icons";
import { TbCaretDown } from "solid-icons/tb";

interface Props {
  name: string;
  validation?: Array<keyof typeof ValidationMethods>;
  value: string;
  options: PseudoSelectOptionProps[];
  placeholder: string;
  modifier?: "thin";
  required?: boolean;
  onChange?: () => void;
  portal: HTMLElement;
}

const mockdata: PseudoSelectOptionProps[] = [
  {
    value: "1",
    label: "Admin",
  },
  {
    value: "2",
    label: "Collaborator",
  },
  {
    value: "3",
    label: "Viewer",
    divider: true,
  },
  {
    value: "",
    label: "Remove",
  },
];

type PseudoSelectOptionProps = {
  label: string;
  value: string;
  icon?: IconTypes;
  divider?: boolean;
  onClick?: () => void;
};

export const PseudoSelect = (props: Props) => {
  const id = `pseudo-select-${createUniqueId()}`;
  const signal = useUIState().pseudoSelectMenu;
  const [open, setOpen] = signal;
  const [value, setValue] = createSignal(props.value);
  const [triggerRef, setTriggerRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  const [inputRef, setInputRef] = createSignal<HTMLInputElement | null>(null);

  const choose = (entry: PseudoSelectOptionProps) => {
    return function onClick() {
      setValue(entry.value);
      setOpen("");
      const input = inputRef();
      if (input) {
        input.value = value();
        input.dispatchEvent(new Event("change", { bubbles: true }));
      }
      if (entry.onClick) {
        entry.onClick();
      }
    };
  };

  const items: ContextMenuProps["items"] = props.options.map((entry) => {
    return {
      label: entry.label,
      icon: entry.icon,
      onClick: choose(entry),
      divider: !!entry.divider,
    };
  });

  return (
    <div
      class={classNames(
        styles["pseudo-select"],
        open() === id ? styles["pseudo-select--open"] : "",
        props.modifier === "thin" ? styles["pseudo-select--thin"] : "",
      )}
    >
      <button id={id} class={styles["pseudo-select__trigger"]} type="button" ref={(ref) => setTriggerRef(ref)}>
        <Show when={value() === ""}>
          <span class={styles["pseudo-select__placeholder"]}>
            {props.placeholder}

            <StIcon
              class={classNames(styles["pseudo-select__caret"], !!open() && styles["pseudo-select__caret--open"])}
              icon={TbCaretDown}
            />
          </span>
        </Show>
        <Show when={value() !== ""}>
          <span class={styles["pseudo-select__selected-text"]}>
            {props.options.find((entry) => entry.value === value())?.label}

            <StIcon
              class={classNames(styles["pseudo-select__caret"], !!open() && styles["pseudo-select__caret--open"])}
              icon={TbCaretDown}
            />
          </span>
        </Show>
      </button>
      <Show when={props.value !== undefined}>
        <input
          type="hidden"
          id={props.name}
          name={props.name}
          value={value()}
          required={props.required}
          data-validation={props.required ? "required" : ""}
          onChange={props.onChange}
          ref={(ref) => setInputRef(ref)}
        />
      </Show>
      <Portal mount={props.portal}>
        <ContextMenu
          id={id}
          items={items}
          bound={triggerRef()}
          signal={signal}
          placement="bottom"
          class={styles["pseudo-select__menu"]}
          offset={4}
          strategy="absolute"
        />
      </Portal>
    </div>
  );
};
