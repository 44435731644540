import { useNavigate } from "@solidjs/router";
import styles from "./CollectionChatsDrawerScreen.module.css";
import { TbFolderFilled } from "solid-icons/tb";
import { CollectionContents } from "@core/components/CollectionContents";
import { ContentTableContainer } from "@core/components/containers/ContentTableContainer";
import { SectionHeader } from "@core/components/SectionHeader";
import { urls } from "@core/lib/urls";
import { Suspense, type Component } from "solid-js";
import { useWire } from "@core/wire";

export const CollectionChatsDrawerScreen: Component<{
  collectionId: string;
}> = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const navigateToThread = (id: string) => navigate(urls.thread(id));
  const collection = () => wire.services.collections.getCollection(props.collectionId);

  const [threads] = wire.services.threads.resourceCollectionThreads(() => props.collectionId);

  return (
    <Suspense>
      <div>
        <p class={styles["collection-chats-drawer__section-title"]}>All Chats</p>

        <ContentTableContainer modifier="solo">
          <SectionHeader title="All chats in this Collection" icon={TbFolderFilled} />
          <CollectionContents
            onMainClick={navigateToThread}
            collectionName={collection()?.label || ""}
            data={threads()?.data.result.entities.map((t) => t.data) || []}
          />
        </ContentTableContainer>
      </div>
    </Suspense>
  );
};
