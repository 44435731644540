import { For, type ParentProps, children } from "solid-js";
import { classNames } from "@core/lib/classNames";
import styles from "./FlexibleGrid.module.css";

interface Props extends ParentProps {
  class?: string;
  columns?: "one" | "two" | "three" | "four";
  stretch?: boolean;
  gap?: "large";
  margin?: "large" | "xl";
}

export const FlexibleGrid = (props: Props) => {
  const resolved = children(() => props.children);
  return (
    <div
      class={classNames(
        styles["flexible-grid"],
        props.columns ? styles[`flexible-grid--${props.columns}`] : "",
        props.stretch ? styles["flexible-grid--stretch"] : "",
        props.gap ? styles[`flexible-grid--${props.gap}-gap`] : "",
        props.margin ? styles[`flexible-grid--${props.margin}-margin`] : "",
        props.class,
      )}
    >
      <div class={styles["flexible-grid__container"]}>
        <For each={resolved.toArray()}>
          {(child) => child && <div class={styles["flexible-grid__item"]}>{child}</div>}
        </For>
      </div>
    </div>
  );
};
