import { For, type ParentProps, Show } from "solid-js";
import { UserAvatar, type UserAvatarProps } from "@core/components/UserAvatar";
import styles from "./UserAvatarNest.module.css";

interface Props extends ParentProps {
  max: number;
  avatars: UserAvatarProps[];
}

export const UserAvatarNest = (props: Props) => {
  return (
    <span class={styles["user-avatar-next"]}>
      <span class={styles["user-avatar-nest__list"]}>
        <Show when={props.avatars.length > props.max}>
          <For each={props.avatars.slice(0, props.max)}>
            {(element) => (
              <span class={styles["user-avatar-nest__item"]}>
                <UserAvatar {...element} />
              </span>
            )}
          </For>
          <span class={styles["user-avatar-nest__item"]}>
            <UserAvatar
              size="share"
              initial={`+${props.avatars.slice(props.max).length}`}
              name={`${props.avatars.slice(props.max).length} other users`}
            />
          </span>
        </Show>
        <Show when={props.avatars.length <= props.max}>
          <For each={props.avatars}>
            {(element) => (
              <span class={styles["user-avatar-nest__item"]}>
                <UserAvatar {...element} />
              </span>
            )}
          </For>
        </Show>
      </span>
    </span>
  );
};
